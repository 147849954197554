import Vue from 'vue'
import App from './App.vue'
import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css'

Vue.use(Vuesax, {
  // options here
})

import 'boxicons'


Vue.config.productionTip = false



new Vue({
  render: function (h) { return h(App) },
}).$mount('#app')
