<template>
  <div>


    <div class="grid-container" @click="handleClick">
      <vs-alert gradient color="success" class="alert">
        <div>
          <h1>
            🎉🎉🎉我们不保证全网最低价,我们只保证30天售后🎉🎉🎉</h1>
        </div>
      </vs-alert>
    </div>

    <div class="grid-container">

      <vs-button warn gradient :active="active == 3" @click="navigateToShop">
        <i class="bx bxs-bell-ring"></i> 进入小铺
      </vs-button>

    </div>


    <h1 class="title">独角兽PLUS</h1>
    <img src="djs.svg" alt="独角兽" style="width: 200px;">




    <h1>PLUS 独立账户 x PLUS 拼团账号</h1>
    <h1 class="date">
      <!-- <p style="color:red">本站最近需要打几个网站广告,欢迎站主联系tg:<a href="https://t.me/shbbsnd">https://t.me/shbbsnd</a> 报价,也可互换广告位</p> -->
      <p style="color:blue">
        🤖独角兽PLUS最靠谱:
        <a href="https://shop.djschatai.com" target="_blank">
          🦄独角兽小铺
        </a>
        👈点击直达访问
      </p>
      <p style="color:rgb(14, 204, 204)">
        关注我们的tg和twitter获取最新活动:
        <a href="https://t.me/getfreeai" target="_blank">
          🚀独角兽小铺TG群
        </a>
        and
        <a href="https://twitter.com/new_craw" target="_blank">
          🐔独角兽小铺Twitter
        </a>
      </p>
    </h1>





    <div class="content__default" style="text-align: center;">

      <vs-alert gradient style="max-width: 600px; margin: auto; display: block; text-align: left;">
        <!-- <template #title>
    Vuesax Framework
  </template> -->
        <h3>揭秘网络诈骗的黑暗面——ChatGPT Plus会员的“黑色产业链”</h3>
      </vs-alert>

    </div>

    <br>
    <div class="content__default"
      style="text-align: center;max-width: 600px; margin: auto; display: block; text-align: left;">

      <vs-alert style="">
        在聊天机器人AI ChatGPT
        Plus的会员官方网站上，会员价格标为20美元，折合人民币约为143元。但令人惊讶的是，我们在各大电商平台，包括某宝，某多，某鱼等，甚至包括一些个人卖家，都能以接近官方价格的150元，140元，甚至130元的价格购买到该会员。这些卖家是自贴腰包用爱做ai的布道者吗?



      </vs-alert>


      <vs-card-group>
          <vs-card v-for="card in cards" :key="card.id" @click="handleClick(card.id)">
            <template #title>
              <h3>{{ card.title }}</h3>
            </template>
            <template #img>
              <img :src="card.imgSrc" alt="">
            </template>
            <template #text>
              <p>{{ card.text }}</p>
            </template>
            <template #interactions>

            </template>
          </vs-card>
        </vs-card-group>



    </div>

    <br>





    <div class="content__default" style="text-align: center;max-width: 600px; margin: auto; display: block; text-align: left;">

      <vs-alert style="">
        事实上，许多消费者反映，购买了这些非官方的ChatGPT
        Plus会员后，使用一周，五天，甚至隔天就会被封号或失去会员资格。尽管有些人以频繁切换代理，未开启无痕模式，使用中文提问等因素为由，解释了这些问题的出现，然而，真正的原因其实远比这些复杂得多
      
      </vs-alert>
      

      <img src="1.png" alt="独角兽" style="max-width: 100%; height: auto;">


    </div>
    <br>

    <div class="content__default" style="text-align: center;">

      <vs-alert style="max-width: 600px; margin: auto; display: block; text-align: left;">
        经过多日的深入调查和研究，我们想要通过本篇文章揭示一条隐藏在ChatGPT Plus会员背后的黑色产业链。在这里，我们提前提示大家：你可能会惊讶于其中的真相。这也正是我们写这篇文章的目的——帮助人们理解和避免这类诈骗。
      </vs-alert>

    </div>

    <br>
    <div class="content__default" style="text-align: center;max-width: 600px; margin: auto; display: block; text-align: left;">

      <vs-alert style="">
        在一个名为“青帝”的群里，许多商家在热议今天的Plus会员价格。80元，90元的价格或许已经让你震惊，但真正让人惊愕的是，这些价格仅仅是二手市场的交易价格，真正的拿货价格远远低于这些。
      </vs-alert>



      <vs-card-group>
          <vs-card v-for="card in cards2" :key="card.id" @click="handleClick(card.id)">
            <template #title>
              <h3>{{ card.title }}</h3>
            </template>
            <template #img>
              <img :src="card.imgSrc" alt="">
            </template>
            <template #text>
              <p>{{ card.text }}</p>
            </template>
            <template #interactions>

            </template>
          </vs-card>
        </vs-card-group>

    </div>
    <br>

    <div class="content__default" style="text-align: center;">

      <vs-alert style="max-width: 600px; margin: auto; display: block; text-align: left;">
        为了理解他们是如何以如此低的价格购得Plus会员，我们必须先了解一个名词
      </vs-alert>

    </div>
    <br>

    <div class="content__default" style="text-align: center;">

      <vs-alert relief style="max-width: 600px; margin: auto; display: block; text-align: left;" color="danger">
        <div style="text-align: center;">
          <h1 style="font-weight: bold;">CVV黑产</h1>
        </div>
      </vs-alert>

    </div>
    <br>


    <br>

    <div class="content__default"
      style="text-align: center;max-width: 600px; margin: auto; display: block; text-align: left;">



      <vs-alert style="" color="dark">

        <template #title>
          要理解这个过程，首先我们需要了解几个相关的名词：
        </template>


        <ul style="padding-left: 11px;">
          <li>CVV：Card Verification Value，信用卡验证值，也被称为安全码，是信用卡背面的三位数字，通常在签名条上面。</li><br>
          <li>通道：在CVV黑产中，"通道"一词通常指的是一个能够完成盗刷交易的平台或系统。例如，某些电商网站，由于安全漏洞，可能成为"通道"。</li><br>
          <li>料商：这是CVV黑产中的一个关键角色。他们通常通过各种非法手段获取大量信用卡的详细信息，然后以一种“料”的形式销售给其他黑产参与者。这些"料"包括信用卡号，持卡人姓名，地址，甚至CVV等信息。</li><br>
          <li>买料：在这个黑产链条中，购买信用卡信息的行为被称为"买料"。</li>
        </ul>



      </vs-alert>




    </div>

    <br>
    <div class="content__default" style="text-align: center;">


      <vs-alert style="max-width: 600px; margin: auto; display: block; text-align: left;">


        <template #title>
          让我们揭示盗刷的整个产业链条
        </template>
        首先，料商通过各种途径，比如网络钓鱼、恶意软件等方式，获取大量的信用卡信息，这些信息被称为“料”。然后，料商把这些“料”卖给黑产参与者，这个过程被称为“买料”。

        <br>
        <br>

        购买了“料”的黑产参与者，然后会通过找寻各种"通道"进行盗刷。"通道"一词在这里指的是一个能够完成盗刷交易的平台或系统，这些平台由于缺乏足够的安全措施，使得黑产参与者能够利用别人的信用卡信息进行非法消费。


      </vs-alert>

    </div>
    <br>


    <div class="content__default" style="text-align: center;max-width: 600px; margin: auto; display: block; text-align: left;">

      <vs-alert style="">
        那么这条关于低价Plus会员的交易链条也就同时变得清晰起来了。这些所谓的低价Plus会员，是源头商家是从所谓的“料商”处以低价购得大量信用卡信息，然后利用这些信息大量开通Plus会员，然后通过分销商把这些会员账号卖给普通用户。而当OpenAI的官方检测到这批账号存在问题时，就会对这些账号进行封号或降级操作。
      </vs-alert>

      <img src="2.png" alt="独角兽" style="max-width: 100%; height: auto;">

    </div>
    <br>
    <div class="content__default" style="text-align: center;">

      <vs-alert style="max-width: 600px; margin: auto; display: block; text-align: left;">
        因此，你的Plus会员是否会遇到问题，其实跟你的操作关系并不大。问题的关键在于，你的Plus会员是通过什么渠道获得的。所以，消费者在购买这类产品时一定要注意，避免成为这种网络诈骗的受害者。
      </vs-alert>

    </div>
    <br>

    <div style="display: block;"></div>

    <div style="display: block;"></div>


    <div class="grid-container" @click="handleClick">
      <vs-alert gradient color="success" class="alert">
        <div>
          <h1>
            独角兽PLUS,只出售正规PLUS
          </h1>
        </div>
      </vs-alert>
    </div>

    <h1 class="date">
      <!-- <p style="color:red">本站最近需要打几个网站广告,欢迎站主联系tg:<a href="https://t.me/shbbsnd">https://t.me/shbbsnd</a> 报价,也可互换广告位</p> -->
      <p style="color:blue">
        🤖独角兽PLUS最靠谱:
        <a href="https://shop.djschatai.com" target="_blank">
          🦄独角兽小铺
        </a>
        👈点击直达访问
      </p>
      <p style="color:rgb(14, 204, 204)">
        关注我们的tg和twitter获取最新活动:
        <a href="https://t.me/getfreeai" target="_blank">
          🚀独角兽小铺TG群
        </a>
        and
        <a href="https://twitter.com/new_craw" target="_blank">
          🐔独角兽小铺Twitter
        </a>
      </p>
    </h1>







  </div>
</template>
<script>
import axios from 'axios';
export default {
  data: () => ({
    successFace: false,
    currentDate: new Date().toLocaleDateString(),

    keys: [],
    keyyhqs: [],
    zhyhqs: [],

    istwitter: false,

    yzm: '',
    cards: [
      {
        id: 1,
        title: "官方价格",
        imgSrc: "3.png",
        text: "约145美元",
       
      },
      {
        id: 2,
        title: "淘宝价格",
        imgSrc: "4.png",
        text: "一个赚5块",
        
      }
    ],
    cards2: [
      {
        id: 1,
        title: "黑卡plus",
        imgSrc: "5.png",
        text: "80元左右",
       
      },
      {
        id: 2,
        title: "黑卡plus",
        imgSrc: "6.png",
        text: "80元左右",
       
        
      }
      ,
      {
        id: 3,
        title: "黑卡plus",
        imgSrc: "7.png",
        text: "80元左右",
       
        
      }
    ]

  }),

  async mounted() {
    try {

      const response = await fetch('/api.txt');
      const text = await response.text();
      const keyStrings = text.trim().split('\n');
      this.keys = keyStrings.map(key => ({ key }));

      const response2 = await fetch('/keyyhq.txt');
      const text2 = await response2.text();
      const keyyhq = text2.trim().split('\n');
      this.keyyhqs = keyyhq.map(keyyhq => ({ keyyhq }));

      const response3 = await fetch('/zhyhq.txt');
      const text3 = await response3.text();
      const zhyhq = text3.trim().split('\n');
      this.zhyhqs = zhyhq.map(line => {
        const [account, password] = line.split('---');
        return { account, password };
      });



    } catch (error) {
      console.error(`Error reading keys from file: ${error}`);
    }



    this.getCurrentDate()
  },

  methods: {

    handleClick: function () {
      // 在这里编写点击事件的处理逻辑
      window.open('https://shop.djschatai.com', '_blank');

    },

    navigateToShop() {
      // 获取当前访问的域名
      const currentDomain = window.location.hostname;

      // 定义不同的子域名
      const shopDomain1 = 'shop.djschatai.com';
      const shopDomain2 = 'shop.djschatai.com';

      // 根据当前域名设置要跳转的子域名
      let targetShopDomain;
      if (currentDomain === 'freeopenai.xyz') {
        targetShopDomain = shopDomain2;
      } else if (currentDomain === 'openai3.xyz') {
        targetShopDomain = shopDomain2;
      } else {
        targetShopDomain = shopDomain2;
      }

      // 跳转到目标子域名
      window.location.href = `https://${targetShopDomain}`;
    },

    getCurrentDate() {
      const today = new Date()
      const year = today.getFullYear()
      let month = today.getMonth() + 1
      let day = today.getDate()

      // 如果月份或日期小于10，则在前面添加一个0
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day

      this.currentDate = year + '-' + month + '-' + day
    },

    handleClickFace() {
      this.loadingFace = true

      setTimeout(() => {
        this.loadingFace = false
        this.successFace = !this.successFace
      }, 2000);
    },
    copy: function (text) {


      this.successFace = !this.successFace


      var input = document.createElement('input');
      input.setAttribute('value', text);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');

        const noti = this.$vs.notification({

          position: 'top-center',
          color: 'success',
          title: '复制成功',

        })

        console.log('复制成功');
      }
      document.body.removeChild(input);

    },
    async chaxun(text) {


      let data = {
        "model": "text-ada-001",
        "prompt": "Hello, world!",
        "max_tokens": 5
      }
      axios.post('https://api.openai.com/v1/completions', data, {
        headers: {
          'Authorization': 'Bearer ' + text,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {

          const noti = this.$vs.notification({
            position: 'top-center',
            color: 'success',
            title: '查询成功',
            text: `此api仍有效`
          })


        })
        .catch(error => {
          const noti = this.$vs.notification({
            position: 'top-center',
            color: 'danger',
            title: '查询失败',
            text: `此api已经失效`
          })
        });

    }
  }
}
</script>


<style scoped>
.aiimg {
  width: 20%;

}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}


.alert {
  width: 60%;
}

.grid-container {
  display: grid;
  justify-items: center;


}


@media screen and (max-width: 600px) {
  .vstable {
    width: 100%;
  }

  .alert {
    width: 100%;
  }

  .aiimg {
    width: 70%;

  }
}

.vs-table table {
  margin: 0px;
  border-collapse: collapse;
  /* width: 100%; */
  min-width: 5px !important;
  border: 0px;
}


.vs-table__td {
  max-width: 900px;
  justify-content: center;
}

.title {
  font-size: 60px;
  font-weight: 300;
  color: #42b983;
  margin-bottom: 0;
}

.date {
  font-size: 20px;
  font-weight: 300;
  color: #42b983;
  margin-bottom: 0;
}

.title2 {
  text-align: left;
  display: block;
}


.center {
  display: grid;
  place-items: center;
}

@media screen and (max-width: 600px) {
  .center {
    display: block;
  }
}

.examplex {
  min-width: 5px
}

.text-center {
  text-align: center;
}
</style>